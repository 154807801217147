function onFetchInit(data) {
  let form = {}
  let typeData = {}
  data.configLabels.forEach((e, i) => {
    form['name' + i] = e.name
    typeData['label' + i] = e.labelTypes[0].farmLabelDataVOS.map(el => ({
      name: el.labelName,
      value: el.value,
      select: el.status == 0 ? 1 : 0
    }))
    form['label' + i] = typeData['label' + i]
  })
  return {
    form,
    typeData
  }
}

function onSubmit({ detail, form }) {
  const obj = { ...detail }
  obj['configLabels'].forEach((e, i) => {
    e['labelTypes'][0]['farmLabelDataVOS'].forEach((el, index) => {
      el.status = form['label' + i][index].select == 0 ? 1 : 0
    })
  })
  return obj
}

export default {
  type: 'cardForm',
  url: '/config/farmConfigLabel/list',
  edit: '/config/farmConfigLabel/update',
  params: {
    type: '3'
  },
  onFetchInit,
  onSubmit,
  data: (netData = {}) => {
    return (netData.configLabels || []).map((e, i) => {
      return {
        form: [
          {
            name: (e.name || '') + '-标签',
            type: 'input',
            key: 'name' + i
          },
          {
            name: (e.name || '') + '-筛选',
            type: 'labelGroup',
            key: 'label' + i
          }
        ]
      }
    })
  }
}
